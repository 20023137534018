import type { MemberFormat } from './analyticsFormat'
import { PermissionLevel } from './permissions'
import type { RenamingField } from './renaming'
import type { NormalizedFieldEntry } from './sourceValue'

export enum FilterOperator {
  EQUALS = 'equals',
  NOT_EQUALS = 'notEquals',
  CONTAINS = 'contains',
  NOT_CONTAINS = 'notContains',
  BEFORE_DATE = 'beforeDate',
  AFTER_DATE = 'afterDate',
  GT = 'gt',
  GTE = 'gte',
  LT = 'lt',
  LTE = 'lte',
  SET = 'set',
  NOT_SET = 'notSet',
  IN_DATE_RANGE = 'inDateRange'
}

export enum MeasureCategory {
  ABSENCE = 'employee.absence',
  ARRIVAL = 'employee.arrival',
  CAREER = 'employee.career',
  DEPARTURE = 'employee.departure',
  ORGANIZATION = 'employee.organization',
  PAYROLL = 'employee.payroll',
  SALARY = 'employee.salary',
  MANAGEMENT = 'employee.management',
  TRIAL_PERIOD = 'employee.trialPeriod',
  WORKFORCE = 'employee.workforce',
  RETENTION = 'employee.retention',
  FIRST_YEAR = 'employee.firstYear',
  BDESE = 'employee.bdese',
  EGAPRO_INDEX = 'employee.egaproIndex',
  RECRUITER = 'recruitment.recruiter',
  SPEED = 'recruitment.speed',
  OVERVIEW = 'recruitment.overview',
  FUNNEL = 'recruitment.funnel'
}

export enum DimensionCategory {
  TIME = 'time',
  EMPLOYEE_ORGANIZATION = 'employee.organization',
  EMPLOYEE_CONTRACT = 'employee.contract',
  EMPLOYEE_INFO = 'employee.info',
  EMPLOYEE_DEPARTURE = 'employee.departure',
  EMPLOYEE_ARRIVAL = 'employee.arrival',
  EMPLOYEE_CAREER = 'employee.career',
  EMPLOYEE_MANAGER = 'employee.manager',
  EMPLOYEE_SALARY = 'employee.salary',
  EMPLOYEE_TRIAL_PERIOD = 'employee.trialPeriod',
  EMPLOYEE_ABSENCE = 'employee.absence',
  EMPLOYEE_PAYROLL = 'employee.payroll',
  EMPLOYEE_BDESE = 'employee.bdese',
  EMPLOYEE_EGAPRO_INDEX = 'employee.egaproIndex',
  RECRUITMENT_ORGANIZATION = 'recruitment.organization',
  RECRUITMENT_INFO = 'recruitment.info',
  RECRUITMENT_SOURCE = 'recruitment.source',
  RECRUITMENT_SPEED = 'recruitment.speed',
  RECRUITMENT_STAGE = 'recruitment.stage',
  RECRUITMENT_TIMELINE = 'recruitment.timeline',
  RECRUITMENT_REJECTION = 'recruitment.rejection',
  RECRUITMENT_CANDIDATE = 'recruitment.candidate',
  RECRUITMENT_RECRUITER = 'recruitment.recruiter'
}

export enum OrderValue {
  ASC = 'asc',
  DESC = 'desc'
}

export type Order = [string, OrderValue]

export interface MeasureMeta {
  // TODO update category prop to mandatory - DEV 1014
  category?: MeasureCategory
  permissions: string[]
  currentUserAccessType: PermissionLevel
  format: MemberFormat
  listAndKpiFormat?: MemberFormat
  associatedMeasure?: string
  shouldUseTimeGranularity?: boolean
  showInExplorer?: boolean
  priority?: number
  isFavorable?: boolean
  includeMeasureAsDrillMember?: boolean
  fixedDrillMembers: string[]
  isBenchmarkable?: boolean
  canBeGrouped: boolean
  canNotBeRequestedWithTimeDimensions: boolean
  orderBy?: Order[]
  renamingFields?: RenamingField[]
  normalizationFieldEntries?: NormalizedFieldEntry[]
}

export interface Measure {
  name: string
  title: string
  type: string
  shortTitle: string
  aggType: string
  isVisible: boolean
  drillMembers: string[]
  meta: MeasureMeta
}

export interface DimensionMeta {
  // TODO update category prop to mandatory - DEV 1014
  category?: DimensionCategory
  permissions: string[]
  currentUserAccessType: PermissionLevel
  format: MemberFormat
  listAndKpiFormat?: MemberFormat
  showInTableExplorer?: boolean
  showInGenericExplorer?: boolean
  showInFilter?: boolean
  showInStandardFilter?: boolean
  showInUserScopeFilter?: boolean
  showInLegalReportScopeFilter?: boolean
  priority?: number
  keepOptionsInFilter?: boolean
  renamingFields?: RenamingField[]
  normalizationFieldEntries?: NormalizedFieldEntry[]
}

export enum DimensionType {
  STRING = 'string',
  NUMBER = 'number',
  BOOLEAN = 'boolean',
  TIME = 'time'
}

export interface Dimension {
  name: string
  shortTitle: string
  title: string
  type: DimensionType
  format: string
  isVisible: boolean
  meta: DimensionMeta
}

export interface Joins {
  name: string
  relationship: string
}

export interface Cube {
  name: string
  title: string
  measures: Measure[]
  dimensions: Dimension[]
  joins: Joins[]
}

export interface Meta {
  meta: {
    cubes: Cube[]
  }
  cubes: Cube[]
}
